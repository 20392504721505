import {Component} from "react";
import {InputLabel, MenuItem, Select} from "@mui/material";
import TextField from "@mui/material/TextField";

export type TypedSelectProps<T> = {
    request: () => Promise<T[]>
    display?: (value: T) => string
    id?: (value: T) => number
    name: string
    value?: T
    label: string
    required: boolean
    nullable?: boolean
    onChange: (e: any) => void
    error: string
    nothing?: JSX.Element
}

type TypedSelectState<T> = {
    values?: T[]
}

export default class TypedSelect<T> extends Component<TypedSelectProps<T>, TypedSelectState<T>> {

    state: TypedSelectState<T> = {
        values: undefined
    }

    componentDidMount() {
        this.props.request().then(values => this.setState({values}));
    }

    render() {
        if (this.state.values && this.state.values.length == 0) {
            return this.props.nothing ?? <TextField
				margin="normal"
				fullWidth
				label={this.props.label}
				slotProps={{
                    input: {
                        readOnly: true,
                    },
                }}
				value="Nothing Available"
			/>;
        }
        if (!this.props.value && !this.props.nullable && this.props.required && this.state.values && this.state.values.length > 0) {
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: this.props.id ? this.props.id(this.state.values[0]) : this.state.values[0] as string | number
                }
            });
        }
        return <>
            <InputLabel id={this.props.name + "-label"}>{this.props.label}</InputLabel>
            <Select<T | string | null>
                label={this.props.label}
                value={!this.state.values ? "LOADING" : this.props.value ?? (this.props.nullable ? "NULL" : "")}
                name={this.props.name}
                error={!!this.props.error}
                fullWidth
                onChange={(e) => {
                    if (this.props.nullable && e.target.value === "NULL") {
                        e.target.value = null;
                    }
                    this.props.onChange(e)
                }}
                variant="outlined"
                disabled={!this.state.values}
            >
                {this.state.values ? this.state.values.map(value => <MenuItem value={this.props.id ? this.props.id(value) : value as string | number}>{this.props.display ? this.props.display(value) : value as string}</MenuItem>) : <MenuItem value="LOADING" disabled>Loading...</MenuItem>}
                {this.props.nullable ? <MenuItem value="NULL">None</MenuItem> : null}
            </Select>
        </>
    }

}