import Typography from "@mui/material/Typography";
import {BatteryFull} from "@mui/icons-material";
import {Battery, User} from "../../../../backend/src/types/database";
import {createBattery, deleteBattery, getBatterys, getNotFoundSerialNumbers, updateBattery} from "../../api/battery_api";
import Avatar from "@mui/material/Avatar";
import {ApiErrors} from "../../api/errors";
import Manager from "../editor/Manager";
import AuthContext from "../../auth/AuthContext";
import {Component, ContextType} from "react";
import Box from "@mui/material/Box";
import TypedSelect from "../editor/TypedSelect";
import {getUsers} from "../../api/user_api";

class UserSelect extends Component<{
    name: string
    value: User
    label: string
    required: boolean
    onChange: () => void
    error: string
}> {
    static contextType = AuthContext;
    context!: ContextType<typeof AuthContext>;

    render() {
        return <TypedSelect<User> request={async () => (await getUsers(this.context.token)).data} display={user => user.email} id={user => user.id} nullable={true} {...this.props}/>;
    }
}

class LastSerialNumbers extends Component<{
    name: string
    value: string
    label: string
    required: boolean
    onChange: () => void
    error: string
}> {
    static contextType = AuthContext;
    context!: ContextType<typeof AuthContext>;

    render() {
        let value: string | undefined = this.props.value;
        if (value == "") {
            value = undefined
        }

        return <TypedSelect<string> request={() => getNotFoundSerialNumbers(this.context.token)} {...this.props} value={value}/>;
    }
}

export default class BatteryManager extends Component {
    static contextType = AuthContext;
    context!: ContextType<typeof AuthContext>;

    render() {
        return <Manager<Battery> {...{
            titel: "Battery Manager",
            editorTitel: "Edit Battery",
            createTitel: "New Battery",
            editEdit: {
                displayName: {label: "Display Name"},
                owner: {label: "Battery Owner", component: UserSelect}
            },
            edit: async battery => {
                const result = await updateBattery(this.context.token, battery.id, battery.displayName, battery.owner);
                if (result.error) {
                    return ApiErrors[result.error];
                }
                return null;
            },
            createEdit: {
                displayName: {label: "Display Name"},
                serialNumber: {label: "Serial Number", component: LastSerialNumbers}
            },
            create: async battery => {
                const result = await createBattery(this.context.token, battery.displayName, battery.serialNumber);
                if (result.error) {
                    return ApiErrors[result.error];
                }
                return null;
            },
            delete: async battery => {
                const result = await deleteBattery(this.context.token, battery.id);
                if (result.error) {
                    return ApiErrors[result.error];
                }
                return null;
            },
            dataGetter: getBatterys.bind(this, this.context.token),
            renderElement: battery => <>
                <Avatar sx={{marginRight: 2, height: 70, width: 70}}><BatteryFull fontSize={"large"}/></Avatar>
                <Box>
                    <Typography variant="h4">{battery.displayName}</Typography>
                    <Typography variant="h6">{battery.serialNumber}</Typography>
                </Box>
            </>
        }}/>;
    }
}