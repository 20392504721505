import * as React from "react";
import * as L from "leaflet";
import {LatLngTuple} from "leaflet";
import Box, {BoxProps} from "@mui/material/Box";
import {BatteryLocationDataTimeline} from "../../../../backend/src/types/database";
import {remap} from "../../utils/MathUtils";

type LeafletProps = {
    lat: number | undefined
    long: number | undefined
    zoom?: number
    history?: BatteryLocationDataTimeline[]
} & BoxProps

async function createLeafletStyles(doc: Document) {
    let styles = doc.getElementById('leaflet-css');
    if (styles) {
        return;
    }
    const res = await fetch('https://esm.sh/leaflet/dist/leaflet.css');
    if (!res.ok) {
        throw new Error(`HTTP ${res.status}: ${res.statusText}`);
    }
    const css = await res.text();
    styles = doc.createElement('style');
    styles.id = 'leaflet-css';
    styles.appendChild(doc.createTextNode(css));
    doc.head.appendChild(styles);
}

function Leaflet({lat, long, zoom = 13, history, ...props}: LeafletProps) {
    const root = React.useRef<HTMLDivElement>(null);
    const mapRef = React.useRef<L.Map>();
    const markerRef = React.useRef<L.Circle>();
    const lineRef = React.useRef<L.Polyline>();
    const linemarkerRef = React.useRef<(L.Circle | null)[]>();
    const [stylesInitialized, setStylesIitialized] = React.useState(false);
    const [error, setError] = React.useState<Error>();

    React.useEffect(() => {
        if (root.current) {
            const doc = root.current.ownerDocument;
            createLeafletStyles(doc).then(
                () => setStylesIitialized(true),
                (err) => setError(err),
            );
        }
    }, []);

    React.useEffect(() => {
        if (!mapRef.current && stylesInitialized && root.current) {
            mapRef.current = L.map(root.current);
            L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
                maxZoom: 20,
                subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                attribution: "© Google"
            }).addTo(mapRef.current);
            lineRef.current = L.polyline([], {color: "red"}).addTo(mapRef.current)
            if (history) {
                let line = history.map(v => v.longitude && v.latitude ? [v.latitude, v.longitude] : false).filter(v => !!v) as LatLngTuple[];
                lineRef.current.setLatLngs(line);
                linemarkerRef.current = history.map((v, i) => v.longitude && v.latitude && mapRef.current ?
                    L.circle([v.latitude, v.longitude], {
                        color: "red",
                        radius: 5,
                        fillOpacity: remap(i / history.length, 0, 1, 0.4, 0),
                        opacity: remap(i / history.length, 0, 1, 0.6, 0)
                    }).addTo(mapRef.current)
                    : null);
            }
            if (lat && long) {
                markerRef.current = L.circle([lat, long], {
                    radius: 15
                }).addTo(mapRef.current);
            }
        }

        if (mapRef.current) {
            if (lat && long) {
                mapRef.current.setView([lat, long], zoom);
            } else {
                mapRef.current.setView([10, 0], 1);
            }

            if (linemarkerRef.current) {
                linemarkerRef.current.forEach(v => v ? v.remove() : null);
            }

            if (history) {
                if (lineRef.current) {
                    let line = history.map(v => v.longitude && v.latitude ? [v.latitude, v.longitude] : false).filter(v => !!v) as LatLngTuple[];
                    lineRef.current.setLatLngs(line);
                }
                linemarkerRef.current = history.map((v, i) => v.longitude && v.latitude && mapRef.current ?
                    L.circle([v.latitude, v.longitude], {
                        color: "red",
                        radius: 5,
                        fillOpacity: remap(i / history.length, 0, 1, 0.4, 0),
                        opacity: remap(i / history.length, 0, 1, 0.6, 0)
                    }).addTo(mapRef.current)
                    : null);
            } else {
                if (lineRef.current) {
                    lineRef.current.setLatLngs([]);
                }
            }
        }

        if (markerRef.current && lat && long) {
            markerRef.current.setLatLng([lat, long]);
        }
    }, [stylesInitialized, lat, long, zoom, history]);

    return (
        <Box {...props}>
            {error ? (
                error.message
            ) : (
                <div style={{width: '100%', height: '100%'}} ref={root}/>
            )}
        </Box>
    );
}

export default Leaflet;
