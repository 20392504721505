import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Tooltip} from "@mui/material";
import {EmojiEvents} from "@mui/icons-material";
import {createUser, deleteUser, getUsers, updateUser} from "../../api/user_api";
import Avatar from "@mui/material/Avatar";
import stringAvatar from "../../utils/string_avatar";
import {ApiErrors} from "../../api/errors";
import Manager from "../editor/Manager";
import Validator from "../editor/Validators";
import {Component, ContextType} from "react";
import {User} from "../../../../backend/src/types/database";
import AuthContext from "../../auth/AuthContext";

export default class UserManager extends Component {
	static contextType = AuthContext;
	context!: ContextType<typeof AuthContext>;

	render() {
		return <Manager<User> {...{
			titel: "User Manager",
			editorTitel: "Edit User",
			createTitel: "New User",
			editEdit: {
				firstName: {label: "First Name"},
				lastName: {label: "Last Name"},
				email: {
					label: "Email",
					validate: Validator.isEmail
				},
				admin: {
					label: "Admin"
				}
			},
			edit: async user => {
				const result = await updateUser(this.context.token, user.id, user.firstName, user.lastName, user.email, null, user.admin);
				if (result.error) {
					return ApiErrors[result.error];
				}
				return null;
			},
			delete: async user => {
				const result = await deleteUser(this.context.token, user.id);
				if (result.error) {
					return ApiErrors[result.error];
				}
				return null;
			},
			createEdit: {
				firstName: {label: "First Name"},
				lastName: {label: "Last Name"},
				email: {
					label: "Email",
					validate: Validator.isEmail
				},
				password: {
					label: "Password"
				}
			},
			create: async user => {
				if (!user.password) {
					return "No Password Provided";
				}
				const result = await createUser(this.context.token, user.firstName, user.lastName, user.email, user.password, user.admin);
				if (result.error) {
					return ApiErrors[result.error];
				}
				return null;
			},
			dataGetter: getUsers.bind(this, this.context.token),
			renderElement: user => <>
				<Avatar {...stringAvatar(user.email, {sx: {width: 80, height: 80}})}/>
				<Box sx={{marginLeft: 4, marginTop: 0.5}} width="80%">
					<Typography variant="h6">{user.email}</Typography>
					<Typography variant="h4" display="flex" alignItems="center">
						{user.admin ? <Tooltip title="Admin" placement="top" slotProps={{
							popper: {
								modifiers: [
									{
										name: "offset",
										options: {
											offset: [0, -14]
										}
									}
								]
							}
						}}>
							<EmojiEvents fontSize="large" sx={{marginRight: 2}}/>
						</Tooltip> : null}
						{user.firstName} {user.lastName}
					</Typography>
				</Box></>
		}}/>;
	}
}