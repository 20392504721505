import {ApiResponseCreate, ApiResponsePagination, ApiResponseSuccess, AuthToken} from "../../../backend/src/types/api";
import {User} from "../../../backend/src/types/database";

export async function getUsers(token: AuthToken, page?: number, pageSize?: number): Promise<ApiResponsePagination<User[]>> {
	let query = "";
	if (page) {
		if (!pageSize) {
			throw new Error("Page requested without page size");
		}
		query = "?page=" + page + "&pageSize=" + pageSize;
	}
	const res = await fetch("/api/user/" + query, {
		headers: {"Authorization": token}
	});
	const response = await res.json();
	response.data = response.data.map((u: User | { admin: number }) => {
		return {...u, admin: u.admin === 1};
	});
	return response;
}

export async function createUser(
	token: AuthToken,
	firstName: string,
	lastName: string,
	email: string,
	password: string,
	admin: boolean
): Promise<ApiResponseCreate<{ userId: number }>> {
	const res = await fetch("/api/user/", {
		method: "PUT",
		headers: {"Content-Type": "application/json", "Authorization": token},
		body: JSON.stringify({firstName, lastName, email, password, admin})
	});
	return await res.json();
}

export async function updateUser(
	token: AuthToken,
	id: number,
	firstName: string,
	lastName: string,
	email: string,
	password: null | string,
	admin: boolean
): Promise<ApiResponseSuccess> {
	const res = await fetch("/api/user/" + id + "/", {
		method: "POST",
		headers: {"Content-Type": "application/json", "Authorization": token},
		body: JSON.stringify({firstName, lastName, password, email, admin})
	});
	return await res.json();
}

export async function deleteUser(token: AuthToken, id: number): Promise<ApiResponseSuccess> {
	const res = await fetch("/api/user/" + id + "/", {
		method: "DELETE",
		headers: {"Authorization": token}
	});
	return await res.json();
}
