import {Battery, BatteryData, BatteryLocationData, BatteryLocationDataTimeline} from "../../../../backend/src/types/database";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Accordion, AccordionDetails, AccordionSummary, Button, FormControlLabel, Grid, Switch} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import RealtimeComponent from "../../utils/RealtimeComponent";
import AuthContext from "../../auth/AuthContext";
import {ContextType} from "react";
import {getBatteryData, getBatteryLocationData, getBatteryLocationDataTimeline} from "../../api/battery_api";
import {Gauge} from "@mui/x-charts";
import Leaflet from "../editor/Leaflet";

type DashboardState = {
    batteyData: BatteryData | undefined
    batteyLocation: BatteryLocationData | undefined
    locationHistory: BatteryLocationDataTimeline[] | undefined
    showHistory: boolean
}
type DashboardProps = {
    battery: Battery
    back: (() => void) | null
}

/*
const batteryValueTypeSeries: Record<BatteryValueType, Partial<LineSeriesType>> = {
	capacity: {label: "Capacity Ah", color: "blue"},
	power: {label: "Power A", color: "green"},
	voltage: {label: "Voltage V", color: "yellow"}
};
 */

export default class BatteryDashboard extends RealtimeComponent<DashboardProps, DashboardState> {
    static contextType = AuthContext;
    context!: ContextType<typeof AuthContext>;

    state: DashboardState = {
        batteyData: undefined,
        batteyLocation: undefined,
        locationHistory: undefined,
        showHistory: false
    };

    componentDidMount() {
        super.componentDidMount();
        getBatteryData(this.context.token, this.props.battery).then(batteyData => this.setState({batteyData}));
        getBatteryLocationData(this.context.token, this.props.battery).then(batteyLocation => this.setState({batteyLocation}));
        getBatteryLocationDataTimeline(this.context.token, this.props.battery).then(locationHistory => Array.isArray(locationHistory) ? this.setState({locationHistory}) : null);
    }

    realtimeSourceDidMount() {
        /*
        this.realtimeSource.addEventListener("updateBattery", (e) => {
            const data = JSON.parse(e.data);
            if (data.battery === this.props.battery.serialNumber) {
                this.setState({dataEntrys: [...this.state.dataEntrys, {...data, timestamp: new Date(data.timestamp)}]});
            }
        });
         */
    }

    render() {
        return <Box>
            <Box display="flex" justifyContent="space-between">
                <Typography component="h1" variant="h4">Battery Dashboard</Typography>
                {this.props.back ?
                    <Button variant="contained" color="error" endIcon={<ArrowBack/>} onClick={this.props.back} sx={{height: "50%"}}>Back</Button>
                    : null}
            </Box>
            <Grid container spacing={2} sx={{marginTop: {xs: 1, md: 6}}}>
                <Grid item xs={6}>
                    <Accordion elevation={4} expanded={true}>
                        <AccordionSummary>Display Name</AccordionSummary>
                        <AccordionDetails>{this.props.battery.displayName}</AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={6}>
                    <Accordion elevation={4} expanded={true}>
                        <AccordionSummary>Serial Number</AccordionSummary>
                        <AccordionDetails>{this.props.battery.serialNumber}</AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={6}>
                    <Accordion elevation={4} expanded={true}>
                        <AccordionSummary>Latest Message</AccordionSummary>
                        <AccordionDetails>{new Date(this.props.battery.latestMessage as unknown as string).toLocaleString()}</AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={6}>
                    <Accordion elevation={4} expanded={true}>
                        <AccordionSummary>Version</AccordionSummary>
                        <AccordionDetails>{this.props.battery.version}</AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion elevation={4}>
                        <AccordionSummary>Modem</AccordionSummary>
                        <AccordionDetails>{this.props.battery.modem}</AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{marginTop: {xs: 1, md: 6}, flex: "row", height: {xs: 500, md: 150}}}>
                <Grid item md={12 / 5} xs={6}>
                    <Gauge
                        value={this.state.batteyData ? this.state.batteyData.stateOfCharge : 0}
                        startAngle={-110}
                        endAngle={110}
                        text={
                            ({value, valueMax}) => `${value} / ${valueMax} \nSOC`
                        }
                    />
                </Grid>
                <Grid item md={12 / 5} xs={6}>
                    <Gauge
                        value={this.state.batteyData ? this.state.batteyData.capacity / 1000 : 0}
                        startAngle={-110}
                        endAngle={110}
                        valueMax={200}
                        text={
                            ({value, valueMax}) => `${value} A/H \nCapacity`
                        }
                    />
                </Grid>
                <Grid item md={12 / 5} xs={6}>
                    <Gauge
                        value={this.state.batteyData ? this.state.batteyData.voltage / 1000 : 0}
                        startAngle={-110}
                        endAngle={110}
                        valueMax={14}
                        text={
                            ({value, valueMax}) => `${value} V \nVoltage`
                        }
                    />
                </Grid>
                <Grid item md={12 / 5} xs={6}>
                    <Gauge
                        value={this.state.batteyData ? this.state.batteyData.temperature[0] : 0}
                        startAngle={-110}
                        endAngle={110}
                        valueMax={30}
                        text={
                            ({value, valueMax}) => `${value} °C \nTemperature`
                        }
                    />
                </Grid>
                <Grid item md={12 / 5} xs={6}>
                    <Gauge
                        value={this.state.batteyData ? this.state.batteyData.current / 1000 : 0}
                        startAngle={-110}
                        endAngle={110}
                        valueMax={10}
                        valueMin={-10}
                        text={
                            ({value, valueMax}) => `${value} A \nCurrent`
                        }
                    />
                </Grid>
            </Grid>
            {this.state.batteyLocation ?
                <Box sx={{marginTop: {xs: 1, md: 6}}}>
                    <FormControlLabel control={<Switch checked={this.state.showHistory}/>} onChange={(e, v) => this.setState({showHistory: v})} label="Show Location History"/>
                    <Leaflet lat={this.state.batteyLocation.latitude} long={this.state.batteyLocation.longitude} sx={{width: "100%", height: "50vh"}} history={this.state.showHistory ? this.state.locationHistory : undefined} zoom={18}/>
                </Box>
                : null}
        </Box>;
    }
}