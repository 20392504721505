import {Component, ReactNode} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Card, CardActionArea, CardContent, List, ListItem, Skeleton, TablePagination} from "@mui/material";
import {Add} from "@mui/icons-material";
import EditorModal, {EditorProps} from "./EditorModal";
import CreateModal, {CreateProps} from "./CreateModal";
import {ApiResponsePagination} from "../../../../backend/src/types/api";
import {DatabaseEntity} from "../../../../backend/src/types/database";

type ManagerState<T> = {
	createOpen: boolean
	dataCount: number
	page: number
	rowsPerPage: number
	edit?: T
	data?: T[]
}
type ManagerProps<T extends DatabaseEntity> = {
	titel: string
	dataGetter: (page: number, rowsPerPage: number) => Promise<ApiResponsePagination<T[]>>
	editorTitel: string
	createTitel: string
	editEdit: EditorProps<T>["edit"]
	createEdit: CreateProps<T>["edit"]
	edit: EditorProps<T>["submit"]
	delete?: EditorProps<T>["delete"]
	create: CreateProps<T>["submit"]
	renderElement: (value: T) => ReactNode
}

export default class Manager<T extends DatabaseEntity> extends Component<ManagerProps<T>, ManagerState<T>> {
	state: ManagerState<T> = {
		createOpen: false,
		dataCount: 0,
		page: 0,
		rowsPerPage: 10
	};

	requestData() {
		this.setState({data: undefined});
		this.props.dataGetter(this.state.page, this.state.rowsPerPage).then(response => this.setState({data: response.data, dataCount: response.count}));
	}

	componentDidMount() {
		this.requestData();
	}

	render() {
		return <Box>
			<Typography component="h1" variant="h4">{this.props.titel}</Typography>
			<Box display="flex" flexDirection="row" justifyContent="space-between">
				<Button
					variant="contained"
					size="small"
					sx={{borderRadius: "100%", aspectRatio: "1/1", marginLeft: 3}}
					color="primary"
					onClick={() => this.setState({createOpen: true})}
				><Add/></Button>
				<TablePagination
					component="div"
					count={this.state.dataCount}
					page={this.state.page}
					onPageChange={(e, page) => {
						this.setState({page, data: undefined});
						this.requestData();
					}}
					rowsPerPage={this.state.rowsPerPage}
					onRowsPerPageChange={(e) => {
						this.setState({page: 0, rowsPerPage: parseInt(e.target.value), data: undefined});
						this.requestData();
					}}
				/>
			</Box>
			<CreateModal<T> edit={this.props.createEdit} titel={this.props.createTitel} open={this.state.createOpen} onClose={update => {
				this.setState({createOpen: false});
				if (update) {
					this.requestData();
				}
			}} submit={this.props.create}/>
			{this.state.edit ?
				<EditorModal<T> value={this.state.edit} edit={this.props.editEdit} titel={this.props.editorTitel} submit={this.props.edit} delete={this.props.delete} onClose={update => {
					this.setState({edit: undefined});
					if (update) {
						this.requestData();
					}
				}}/>
				: null}
			<List>
				{this.state.data ? this.state.data.map(value => <ListItem>
						<Card sx={{width: "60%"}}>
							<CardActionArea>
								<CardContent onClick={() => this.setState({edit: value})} sx={{display: "flex", flexDirection: "row"}}>
									{this.props.renderElement(value)}
								</CardContent>
							</CardActionArea>
						</Card>
					</ListItem>)
					: Array(5).fill(<ListItem>
						<Box width="60%" display="flex" flexDirection="row">
							<Skeleton height="100%" width="100%"/>
						</Box>
					</ListItem>)}
			</List>
		</Box>;
	}

}