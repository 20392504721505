import {Component, ContextType, ReactNode} from "react";
import {Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader} from "@mui/material";
import {AppView, AppViewAdmin, AppViewContext} from "./context/AppViewContext";
import AuthContext from "../auth/AuthContext";
import {BatteryFull, Home, Person} from "@mui/icons-material";
import UserManager from "./manager/UserManager";
import BatteryManager from "./manager/BatteryManager";
import BatteryDashboardList from "./manager/BatteryDashboardList";

export const AppViewInfo: Record<AppView | AppViewAdmin, { icon: ReactNode, app: ReactNode, name: string }> = {
	[AppView.HOME]: {
		icon: <Home/>,
		app: <BatteryDashboardList/>,
		name: "Dashboard"
	},
	[AppViewAdmin.USER]: {
		icon: <Person/>,
		app: <UserManager/>,
		name: "User Management"
	},
	[AppViewAdmin.BATTERY]: {
		icon: <BatteryFull/>,
		app: <BatteryManager/>,
		name: "Battery Management"
	}
};
const AppViewValues: AppView[] = [AppView.HOME];

const AppViewAdminValues: AppViewAdmin[] = [AppViewAdmin.USER, AppViewAdmin.BATTERY];

export class SideMenu extends Component {
	static contextType = AppViewContext;
	context!: ContextType<typeof AppViewContext>;

	render() {
		return <Drawer PaperProps={{sx: {zIndex: 80, paddingTop: 8}}} variant="permanent">
			<List component="nav">
				<ListSubheader component="div" inset>
					User Area
				</ListSubheader>
				{AppViewValues.map(v => <ListItemButton onClick={() => this.context.changeView(v)}>
					<ListItemIcon>
						{AppViewInfo[v].icon}
					</ListItemIcon>
					<ListItemText primary={AppViewInfo[v].name}/>
				</ListItemButton>)}
				<AuthContext.Consumer>{({auth}) => auth.admin ? <>
					<Divider sx={{my: 1}}/>
					<ListSubheader component="div" inset>
						Admin Area
					</ListSubheader>
					{AppViewAdminValues.map(v => <ListItemButton onClick={() => this.context.changeView(v)}>
						<ListItemIcon>
							{AppViewInfo[v].icon}
						</ListItemIcon>
						<ListItemText primary={AppViewInfo[v].name}/>
					</ListItemButton>)}
				</> : null}</AuthContext.Consumer>
			</List>
		</Drawer>;
	}
}