import React, {Component, PropsWithChildren, ReactNode} from "react";
import * as jwt from "jwt-decode";
import {signOut} from "../api/auth_api";
import {AuthToken} from "../../../backend/src/types/api";

type UserData = {
	userId: number
	email: string
	admin: boolean
}

type AuthProviderState = {
	loggedIn: boolean
	expired: boolean
	token: AuthToken,
	auth: UserData,
	setToken: (token: AuthToken) => void,
	logout: () => Promise<void>
}

type AuthProviderProperty = {
	login: ReactNode
	debugLogin?: boolean
}

const AuthContext = React.createContext<AuthProviderState>({
	loggedIn: false,
	expired: false,
	token: "undefined",
	auth: {email: "", userId: 0, admin: false},
	setToken: (_: string) => {
	},
	logout: async () => {
	}
});

export class AuthProvider extends Component<PropsWithChildren<AuthProviderProperty>, AuthProviderState> {

	constructor(props: PropsWithChildren<AuthProviderProperty>) {
		super(props);
		this.state = {
			loggedIn: props.debugLogin ?? false,
			expired: false,
			token: "undefinied" as AuthToken,
			auth: {email: "test@cool.de", userId: 1, admin: true},
			setToken: this.setToken.bind(this),
			logout: this.logout.bind(this)
		};
	}

	componentDidMount() {
		const token = localStorage.getItem("auth") as AuthToken;
		if (token) {
			this.setToken(token);
		}
	}

	async logout() {
		localStorage.removeItem("auth");
		if (this.state.token) {
			await signOut(this.state.token);
		}
		this.setState({loggedIn: false});
	}

	setToken(token: AuthToken) {
		try {
			const auth = jwt.jwtDecode(token);
			if (auth.exp) {
				if (auth.exp < Date.now() / 1000) {
					this.setState({expired: true});
					return;
				}
			}
			this.setState({token, auth: auth as UserData, loggedIn: true, expired: false});
			localStorage.setItem("auth", token);
		} catch (e) {
			console.log(e);
		}
	}

	render() {
		return (
			<AuthContext.Provider value={this.state}>
				{(() => (!this.state.loggedIn ? this.props.login : this.props.children))()}
			</AuthContext.Provider>
		);
	}
}

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
