import {Component, createContext, PropsWithChildren} from "react";

export enum AppView {
    HOME
}

export enum AppViewAdmin {
    USER = 1,
    BATTERY
}

export type AppViewValue = AppView | AppViewAdmin

export const AppViewContext = createContext<AppViewContextProviderState>({
    view: AppView.HOME,
    changeView: () => {
    }
});

type AppViewContextProviderProps = {}
type AppViewContextProviderState = {
    view: AppViewValue
    changeView: (view: AppViewValue) => void
}

export class AppViewContextProvider extends Component<PropsWithChildren<AppViewContextProviderProps>, AppViewContextProviderState> {

    state = {
        changeView: this.changeView.bind(this),
        view: AppView.HOME
    }

    changeView(view: AppViewValue) {
        this.setState({view})
    }

    render() {
        return <AppViewContext.Provider value={this.state}>
            {this.props.children}
        </AppViewContext.Provider>;
    }

}