import {Component, ContextType} from "react";
import {Battery} from "../../../../backend/src/types/database";
import AuthContext from "../../auth/AuthContext";
import {Alert, AlertTitle, Card, CardActionArea, CardContent, Grid, Skeleton} from "@mui/material";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import {BatteryFull, SentimentDissatisfied} from "@mui/icons-material";
import BatteryDashboard from "./BatteryDashboard";
import {getMyBatterys} from "../../api/battery_api";
import {ApiErrors} from "../../api/errors";
import Box from "@mui/material/Box";

type DashboardListState = {
    loading: boolean
    selected: number | null
    error: string | undefined
    batterys: Battery[]
}

export default class BatteryDashboardList extends Component<{}, DashboardListState> {
    static contextType = AuthContext;
    context!: ContextType<typeof AuthContext>;

    state: DashboardListState = {
        loading: true,
        selected: null,
        error: undefined,
        batterys: []
    };

    componentDidMount() {
        getMyBatterys(this.context.token).then(response => !Array.isArray(response) ? this.setState({error: ApiErrors[response.error]}) : this.setState({batterys: response, loading: false}));
    }

    render() {
        if (this.state.batterys.length == 1) {
            return <BatteryDashboard back={null} battery={this.state.batterys[0]}/>;
        }
        if (this.state.selected != null) {
            return <BatteryDashboard back={() => this.setState({selected: null})} battery={this.state.batterys[this.state.selected]}/>;
        }
        return <>
            <Typography component="h1" variant="h4">Your Battery's</Typography>
            {!!this.state.error ? <Alert severity="error">
                <AlertTitle>Connection Error</AlertTitle>
                {this.state.error}
            </Alert> : null}
            {this.state.loading ?
                <Grid container spacing={3} sx={{margin: 1}}>
                    {new Array(5).fill(<Grid item md={2} xs={12}>
                        <Card elevation={8}>
                            <CardContent sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                height: 200
                            }}>
                                <Skeleton variant="circular" height="60px" width="60px"/>
                                <Skeleton height="30%" width="80%"/>
                                <Skeleton height="20%" width="70%"/>
                                <Skeleton height="20%" width="60%"/>
                            </CardContent>
                        </Card>
                    </Grid>)}
                </Grid> : (
                    this.state.batterys.length > 0 ?
                        <Grid container spacing={4} sx={{margin: 1, paddingRight: 4}}>
                            {this.state.batterys.map((battery, i) =>
                                <Grid item md={2} xs={12}>
                                    <Card elevation={8}>
                                        <CardActionArea>
                                            <CardContent sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                height: 200
                                            }} onClick={() => this.setState({selected: i})}>
                                                <Avatar><BatteryFull/></Avatar>
                                                <Typography component="h1" variant="h4">{battery.displayName}</Typography>
                                                <Typography component="h1" variant="h5">Battery</Typography>
                                                {battery.serialNumber}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>)}
                        </Grid> :
                        <Box width="60%" display="flex" alignItems="center" flexDirection="column">
                            <SentimentDissatisfied sx={{fontSize: "400%"}}/>
                            <Typography component="h1" variant="h4">You dont have Battery's</Typography>
                        </Box>
                )
            }
        </>;
    }
}