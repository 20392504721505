import {ApiResponse, ApiResponseCreate, ApiResponseError, ApiResponsePagination, ApiResponseSuccess, AuthToken} from "../../../backend/src/types/api";
import {Battery, BatteryData, BatteryLocationData, BatteryLocationDataTimeline} from "../../../backend/src/types/database";

export async function getBatterys(token: AuthToken, page?: number, pageSize?: number): Promise<ApiResponsePagination<Battery[]>> {
    let query = "";
    if (page) {
        if (!pageSize) {
            throw new Error("Page requested without page size");
        }
        query = "?page=" + page + "&pageSize=" + pageSize;
    }
    const res = await fetch("/api/battery/" + query, {
        headers: {"Authorization": token}
    });

    if (res.status != 200) {
        return {
            error: "internal_error",
            message: res.statusText,
            data: [],
            count: 0
        };
    }
    return await res.json();
}

export async function updateBattery(
    token: AuthToken,
    id: number,
    displayName: string,
    owner: number | null
): Promise<ApiResponseSuccess> {
    const res = await fetch("/api/battery/" + id + "/", {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": token},
        body: JSON.stringify({displayName, owner})
    });

    if (res.status != 200) {
        return {
            error: "internal_error",
            message: res.statusText,
            success: false
        };
    }
    return await res.json();
}

export async function deleteBattery(token: AuthToken, id: number): Promise<ApiResponseSuccess> {
    const res = await fetch("/api/battery/" + id + "/", {
        method: "DELETE",
        headers: {"Authorization": token}
    });
    return await res.json();
}

export async function createBattery(
    token: AuthToken,
    name: string,
    serial: string
): Promise<ApiResponseCreate<{ batteryId: number }>> {
    const res = await fetch("/api/battery/", {
        method: "PUT",
        headers: {"Content-Type": "application/json", "Authorization": token},
        body: JSON.stringify({name, serial})
    });

    if (res.status != 200) {
        return {
            error: "internal_error",
            message: res.statusText
        } as ApiResponseCreate<{ batteryId: number }>;
    }
    return await res.json();
}

export async function getMyBatterys(
    token: AuthToken
): Promise<ApiResponseError<Battery[]>> {
    const res = await fetch("/api/battery/my", {
        method: "GET",
        headers: {"Authorization": token}
    });

    if (res.status != 200) {
        return {
            error: "internal_error",
            message: res.statusText
        };
    }
    return await res.json();
}


export async function getBatteryData(
    token: AuthToken,
    battery: Battery
): Promise<ApiResponse<BatteryData>> {
    const res = await fetch("/api/battery/" + battery.serialNumber + "/data", {
        method: "GET",
        headers: {"Authorization": token}
    });
    if (res.status != 200) {
        return {
            error: "internal_error",
            message: res.statusText
        } as ApiResponse<BatteryData>;
    }
    return await res.json();
}

export async function getBatteryLocationData(
    token: AuthToken,
    battery: Battery
): Promise<ApiResponse<BatteryLocationData>> {
    const res = await fetch("/api/battery/" + battery.serialNumber + "/location", {
        method: "GET",
        headers: {"Authorization": token}
    });

    if (res.status != 200) {
        return {
            error: "internal_error",
            message: res.statusText
        } as ApiResponse<BatteryLocationData>;
    }
    return await res.json();
}

export async function getBatteryLocationDataTimeline(
    token: AuthToken,
    battery: Battery
): Promise<ApiResponseError<BatteryLocationDataTimeline[]>> {
    const res = await fetch("/api/battery/" + battery.serialNumber + "/location/timeline", {
        method: "GET",
        headers: {"Authorization": token}
    });
    if (res.status != 200) {
        return {
            error: "internal_error",
            message: res.statusText
        };
    }
    return await res.json();
}

export async function getNotFoundSerialNumbers(
    token: AuthToken
): Promise<string[]> {
    const res = await fetch("/api/battery/notfound", {
        method: "GET",
        headers: {"Authorization": token}
    });
    if (res.status != 200) {
        return [];
    }
    return await res.json();
}