import {Component} from "react";
import Login from "./auth/Login";
import {createTheme, PaletteMode, ThemeProvider} from "@mui/material";
import {AuthProvider} from "./auth/AuthContext";
import MainApp from "./dashboard/MainApp";

type AppState = {
    mode: PaletteMode;
}
type AppProperties = {}

class App extends Component<AppProperties, AppState> {

    state = {mode: "light" as PaletteMode};

    toggleColorMode() {
        this.setState((prev) => {
            return {
                mode: prev.mode === "light" ? "dark" : "light"
            }
        });
    }

    render() {
        return (<>
            <ThemeProvider theme={createTheme({palette: {primary: {main: "#405C73"}, secondary: {main: "#EB5F0A"}, mode: this.state.mode}})}>
                <AuthProvider login={<Login/>}>
                    <MainApp/>
                </AuthProvider>
            </ThemeProvider>
        </>);
    }
}

export default App;
