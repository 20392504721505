import {Component, ContextType} from "react";
import {Alert, AlertTitle, Modal, Paper} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {LoadingButton} from "@mui/lab";
import {ApiErrors} from "../api/errors";
import {signUp} from "../api/auth_api";
import AuthContext from "./AuthContext";

type RegisterModalProps = {
	open: boolean
	onClose: (update: boolean) => void
}

type RegisterModalState = {
	loading: boolean,
	error: string | undefined,
	firstName: string,
	firstNameError: string | undefined,
	lastName: string,
	lastNameError: string | undefined,
	email: string,
	emailError: string | undefined,
	password: string,
	passwordError: string | undefined
}

export default class RegisterModal extends Component<RegisterModalProps, RegisterModalState> {
	static contextType = AuthContext;
	context!: ContextType<typeof AuthContext>;

	state: RegisterModalState = {
		loading: false,
		error: undefined,
		firstName: "",
		firstNameError: undefined,
		lastName: "",
		lastNameError: undefined,
		email: "",
		emailError: undefined,
		password: "",
		passwordError: undefined
	};

	constructor(props: RegisterModalProps) {
		super(props);

		this.register = this.register.bind(this);
		this.change = this.change.bind(this);
	}

	async register() {
		let ready = true;
		if (this.state.firstName === "") {
			this.setState({firstNameError: "First Name is Empty"});
			ready = false;
		}
		if (this.state.lastName === "") {
			this.setState({lastNameError: "Last Name is Empty"});
			ready = false;
		}
		if (this.state.email === "") {
			this.setState({emailError: "Email is Empty"});
			ready = false;
		} else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(this.state.email)) {
			this.setState({emailError: "Email is not valide"});
			ready = false;
		}
		if (this.state.password === "") {
			this.setState({passwordError: "Password is Empty"});
			ready = false;
		} else if (this.state.password.length < 8) {
			this.setState({passwordError: "Password is to short"});
			ready = false;
		}
		if (ready) {
			this.setState({loading: true});
			const result = await signUp(this.context.token, this.state.firstName, this.state.lastName, this.state.email, this.state.password);
			if (result.error) {
				this.setState({loading: false, error: ApiErrors[result.error]});
			} else {
				this.props.onClose(true);
			}
		}
	}

	change(e: any) {
		if (e.target.value !== "") {
			this.setState({[e.target.name + "Error"]: undefined} as unknown as Pick<RegisterModalState, keyof RegisterModalState>);
		}
		this.setState({
			[e.target.name]: e.target.value
		} as unknown as Pick<RegisterModalState, keyof RegisterModalState>);
	}

	render() {
		return <Modal open={this.props.open} onClose={() => this.props.onClose(false)}>
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center"
				}}
			>
				<Paper sx={{padding: 6}}>
					<Typography component="h1" variant="h5">
						Sign up
					</Typography>
					<Box sx={{mt: 1}}>
						{!!this.state.error ? <Alert severity="error">
							<AlertTitle>Register Error</AlertTitle>
							{this.state.error}
						</Alert> : null}
						<Box display="flex" flexDirection="row" gap={2}>
							<TextField
								margin="normal"
								required
								fullWidth
								name="firstName"
								label="First Name"
								inputProps={{maxLength: 50}}
								autoComplete="given-name"
								value={this.state.firstName}
								onChange={this.change}
								error={!!this.state.firstNameError}
								helperText={this.state.firstNameError}
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								name="lastName"
								label="Last Name"
								inputProps={{maxLength: 50}}
								autoComplete="family-name"
								value={this.state.lastName}
								onChange={this.change}
								error={!!this.state.lastNameError}
								helperText={this.state.lastNameError}
							/>
						</Box>
						<TextField
							margin="normal"
							required
							fullWidth
							name="email"
							label="Email Address"
							inputProps={{maxLength: 100}}
							autoComplete="email"
							value={this.state.email}
							onChange={this.change}
							error={!!this.state.emailError}
							helperText={this.state.emailError}
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							inputProps={{maxLength: 100}}
							autoComplete="new-password"
							value={this.state.password}
							onChange={this.change}
							error={!!this.state.passwordError}
							helperText={this.state.passwordError}
						/>
						<LoadingButton
							onClick={this.register}
							fullWidth
							loadingPosition="start"
							loading={this.state.loading}
							variant="contained"
							sx={{mt: 3, mb: 2}}
						>
							Sign Up
						</LoadingButton>
					</Box>
				</Paper>
			</Box>
		</Modal>;
	}

}