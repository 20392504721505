import {Component} from "react";
import {JsonSerialized, RealtimeEvent, RealtimeEventType} from "../../../backend/src/types/api";

interface TypedEventSource<T extends string, Events extends Record<T, any>> extends EventSource {
	addEventListener<K extends T>(type: K, listener: (this: EventSource, event: MessageEvent<JsonSerialized<Events[K]>>) => any, options?: boolean | AddEventListenerOptions): void;

	addEventListener<K extends T>(type: K, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;

	removeEventListener<K extends T>(type: K, listener: (this: EventSource, event: MessageEvent<JsonSerialized<Events[K]>>) => any, options?: boolean | EventListenerOptions): void;

	removeEventListener<K extends T>(type: K, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
}

const NullEventSource = null as unknown as EventSource;

export default class RealtimeComponent<P, S> extends Component<P, S> {
	realtimeSource: TypedEventSource<RealtimeEventType, RealtimeEvent>;

	constructor(props: P) {
		super(props);
		this.realtimeSource = NullEventSource;
	}

	componentDidMount() {
		this.realtimeSource = new EventSource("/api/realtime");
		this.realtimeSource.onopen = () => this.realtimeSourceDidMount();
	}

	realtimeSourceDidMount() {
	}

	componentWillUnmount() {
		if (this.realtimeSource) {
			this.realtimeSource.close();
		}
	}
}
