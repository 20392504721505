import {Component, ComponentProps, ContextType} from "react";
import AuthContext from "./AuthContext";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {signIn} from "../api/auth_api";
import {ApiErrors} from "../api/errors";
import {Alert, AlertTitle, Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import RegisterModal from "./RegisterModal";

type LoginState = {
	signup: boolean,
	loading: boolean,
	error: string | undefined,
	email: string,
	emailError: string | undefined,
	password: string,
	passwordError: string | undefined
}
type LoginProperties = {}

export default class Login extends Component<LoginProperties, LoginState> {
	static contextType = AuthContext;
	context!: ContextType<typeof AuthContext>;

	state = {
		signup: false,
		loading: false,
		error: undefined,
		email: "",
		emailError: undefined,
		password: "",
		passwordError: undefined
	};

	constructor(props: LoginProperties) {
		super(props);

		this.login = this.login.bind(this);
		this.change = this.change.bind(this);
	}

	async login() {
		let ready = true;
		if (this.state.email === "") {
			this.setState({emailError: "Email is Empty"});
			ready = false;
		} else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(this.state.email)) {
			this.setState({emailError: "Email is not valide"});
			ready = false;
		}
		if (this.state.password === "") {
			this.setState({passwordError: "Password is Empty"});
			ready = false;
		}
		if (ready) {
			this.setState({loading: true});
			const result = await signIn(this.state.email, this.state.password);
			if (result.error) {
				this.setState({loading: false, error: ApiErrors[result.error]});
			} else {
				this.context.setToken(result.token);
			}
		}
	}

	change(e: any) {
		if (e.target.value !== "") {
			this.setState({[e.target.name + "Error"]: undefined} as unknown as Pick<LoginState, keyof LoginState>);
		}
		this.setState({
			[e.target.name]: e.target.value
		} as unknown as Pick<LoginState, keyof LoginState>);
	}

	render() {
		return (
			<Container component="main" maxWidth="xs">
				<CssBaseline/>
				<RegisterModal open={this.state.signup} onClose={() => this.setState({signup: false})}/>
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center"
					}}
				>
					<Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
						<LockOutlinedIcon/>
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>
					<Box sx={{mt: 1}}>
						{this.context.expired ?
							<Alert severity="info">
								<AlertTitle>Login Expired</AlertTitle>
								Your login has Expired
							</Alert> : null}
						{!!this.state.error ? <Alert severity="error">
							<AlertTitle>Login Error</AlertTitle>
							{this.state.error}
						</Alert> : null}
						<TextField
							margin="normal"
							required
							fullWidth
							name="email"
							label="Email Address"
							inputProps={{maxLength: 100}}
							autoComplete="email"
							value={this.state.email}
							onChange={this.change}
							error={!!this.state.emailError}
							helperText={this.state.emailError}
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							inputProps={{maxLength: 100}}
							autoComplete="current-password"
							value={this.state.password}
							onChange={this.change}
							error={!!this.state.passwordError}
							helperText={this.state.passwordError}
						/>
						<LoadingButton
							onClick={this.login}
							fullWidth
							loadingPosition="start"
							loading={this.state.loading}
							variant="contained"
							sx={{mt: 3, mb: 2}}
						>
							Sign In
						</LoadingButton>
						<Box textAlign="center">
							<Link component="div" sx={{cursor: "pointer"}} onClick={() => this.setState({signup: true})}>
								{"Don't have an account? Sign Up"}
							</Link>
						</Box>
					</Box>
				</Box>
			</Container>
		);
	}
}

export class LogoutButton extends Component<ComponentProps<typeof Button>> {
	static contextType = AuthContext;
	context!: ContextType<typeof AuthContext>;

	render() {
		return (
			<Button {...this.props} onClick={this.context.logout}>
				{this.props.children}
			</Button>
		);
	}
}
