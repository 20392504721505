import {Component} from "react";
import {Alert, AlertTitle, FormControlLabel, Modal, Paper, Switch} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {LoadingButton} from "@mui/lab";
import {Delete} from "@mui/icons-material";

export type EditorProps<T> = {
    titel: string
    value: T
    edit: {
        [K in Extract<keyof T, string>]?: {
            label: string
            required?: boolean
            autoComplete?: string
            component?: typeof Component
            validate?: (value: T[K]) => null | string
        }
    }
    submit: (value: T) => Promise<null | string>
    delete?: (value: T) => Promise<null | string>
    onClose: (update: boolean) => void
}

type EditorState<T> = {
    loading: boolean
    error: string | undefined
    changed: boolean
} & {
    [K in (string & keyof T)]: T[K]
} & {
    [K in (string & keyof T) as `${K}Error`]: string | undefined
} & {
    [K in (string & keyof T) as `${K}Original`]: T[K]
} & {
    [K in (string & keyof T) as `${K}Changed`]: boolean
}

export default class EditorModal<T> extends Component<EditorProps<T>, EditorState<T>> {

    constructor(props: EditorProps<T>) {
        super(props);

        let state = {
            loading: false,
            error: undefined
        } as EditorState<T>;
        for (const key of Object.keys(this.props.edit) as Extract<keyof T, string>[]) {
            (state as any)[key] = props.value[key];
            (state as any)[key + "Original"] = props.value[key];
            (state as any)[key + "Error"] = undefined;
            (state as any)[key + "Changed"] = false;
        }
        this.state = state;

        this.change = this.change.bind(this);
        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this);
    }

    change(e: any) {
        let value = e.target.value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        const state = {[e.target.name as string]: value};
        if (typeof value == "string") {
            value = value.trim();
        }
        if (value !== "") {
            state[e.target.name + "Error"] = undefined;
        }
        state.changed = this.state[e.target.name + "Original" as keyof EditorState<T>] !== value;
        state[e.target.name + "Changed"] = state.changed;

        if (state.changed) {
            for (const key in this.props.value) {
                if (key !== e.target.name) {
                    if ((state as any)[key + "Changed"]) {
                        state.changed = true;
                        break;
                    }
                }
            }
        }
        this.setState(state as unknown as EditorState<T>);
    }

    async submit() {
        let ready: boolean = this.state.changed;
        for (const key in this.props.value) {
            const edit = this.props.edit[key];
            if (edit) {
                let result = null;
                if (typeof edit.required == "undefined" ? true : edit.required) {
                    if (this.state[key] === "") {
                        result = edit.label + " is Required";
                    }
                }

                if (edit.validate && !result) {
                    result = edit.validate(this.state[key] as T[typeof key]);
                }

                if (result) {
                    this.setState({[key + "Error"]: result} as unknown as EditorState<T>);
                    ready = false;
                }
            }
        }
        if (this.props.value && ready) {
            this.setState({loading: true} as unknown as EditorState<T>);
            const result = await this.props.submit({
                ...this.props.value, ...(Object.keys(this.props.edit) as Extract<keyof T, string>[])
                    .reduce((obj, key) => {
                        obj[key] = this.state[key] as T[typeof key];
                        return obj;
                    }, {} as Partial<T>)
            } as T);
            if (result) {
                this.setState({loading: false, error: result} as unknown as EditorState<T>);
            } else {
                this.props.onClose(true);
            }
        }
    }

    async delete() {
        if (!this.props.delete) {
            return;
        }
        this.setState({loading: true} as unknown as EditorState<T>);
        const result = await this.props.delete(this.props.value);
        if (result) {
            this.setState({loading: false, error: result} as unknown as EditorState<T>);
        } else {
            this.props.onClose(true);
        }
    }

    render() {
        return <Modal open={true} onClose={() => this.props.onClose(false)}>
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Paper sx={{padding: 6}}>
                    <Typography component="h1" variant="h5">
                        {this.props.titel}
                    </Typography>
                    <Box sx={{mt: 1}}>
                        {!!this.state.error ? <Alert severity="error">
                            <AlertTitle>{this.props.titel} Error</AlertTitle>
                            {this.state.error}
                        </Alert> : null}
                        {(Object.keys(this.props.edit) as (keyof typeof this.props.edit)[]).map(name => {
                            const edit = this.props.edit[name];
                            if (edit?.component) {
                                const Component = edit.component;
                                return <Component
                                    name={name}
                                    autoComplete={edit?.autoComplete ?? "off"}
                                    value={this.state[name]}
                                    label={edit?.label}
                                    required={edit?.required ?? true}
                                    onChange={this.change}
                                    error={!!(this.state as any)[name + "Error"]}
                                />;
                            }
                            if (typeof this.state[name] == "boolean") {
                                return <FormControlLabel control={<Switch name={name} checked={this.state[name] as boolean} onChange={this.change}/>} label={edit?.label}/>;
                            }
                            return <TextField
                                margin="normal"
                                fullWidth
                                name={name}
                                label={edit?.label}
                                required={edit?.required ?? true}
                                inputProps={{maxLength: 100}}
                                autoComplete={edit?.autoComplete ?? "off"}
                                value={this.state[name]}
                                onChange={this.change}
                                error={!!(this.state as any)[name + "Error"]}
                                helperText={(this.state as any)[name + "Error"]}
                            />;
                        })}
                        <Box display="flex" justifyContent="space-evenly">
                            {this.props.delete ?
                                <LoadingButton
                                    onClick={this.delete}
                                    loadingPosition="start"
                                    loading={this.state.loading}
                                    startIcon={<Delete/>}
                                    color="error"
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                >
                                    Delete
                                </LoadingButton> : null}
                            <LoadingButton
                                onClick={this.submit}
                                disabled={!(this.state.changed)}
                                loadingPosition="start"
                                loading={this.state.loading}
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Update
                            </LoadingButton>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Modal>;
    }
}