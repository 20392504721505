import {Component} from "react";
import {Alert, AlertTitle, FormControlLabel, Modal, Paper, Switch} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {LoadingButton} from "@mui/lab";

export type CreateProps<T> = {
    titel: string
    open: boolean
    edit: {
        [K in Extract<keyof T, string>]?: {
            label: string
            autoComplete?: string
            component?: typeof Component
            validate?: (value: T[K]) => null | string
        }
    }
    submit: (value: T) => Promise<null | string>
    onClose: (update: boolean) => void
}

type CreateState<T> = {
    loading: boolean
    error: string | undefined
} & {
    [K in (string & keyof T)]: T[K]
} & {
    [K in (string & keyof T) as `${K}Error`]: string | undefined
}

export default class CreateModal<T> extends Component<CreateProps<T>, CreateState<T>> {

    constructor(props: CreateProps<T>) {
        super(props);

        let state = {
            loading: false,
            error: undefined
        } as CreateState<T>;
        for (const key of Object.keys(this.props.edit) as Extract<keyof T, string>[]) {
            (state as any)[key] = "";
            (state as any)[key + "Error"] = undefined;
        }
        this.state = state;

        this.submit = this.submit.bind(this);
        this.change = this.change.bind(this);
    }

    change(e: any) {
        let value = e.target.value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        const state = {[e.target.name as string]: value};
        if (value !== "") {
            state[e.target.name + "Error"] = undefined;
        }

        this.setState(state as unknown as CreateState<T>);
    }

    async submit() {
        let ready: boolean = true;
        for (const key of Object.keys(this.props.edit) as Extract<keyof T, string>[]) {
            const edit = this.props.edit[key];
            if (edit) {
                if (edit.validate) {
                    const result = edit.validate(this.state[key] as T[typeof key]);

                    if (result) {
                        this.setState({[key + "Error"]: result} as unknown as CreateState<T>);
                        ready = false;
                    }
                }
            }
        }
        if (ready) {
            this.setState({loading: true} as unknown as CreateState<T>);
            const result = await this.props.submit(
                (Object.keys(this.props.edit) as Extract<keyof T, string>[])
                    .reduce((obj, key) => {
                        obj[key] = this.state[key] as T[typeof key];
                        return obj;
                    }, {} as T)
            );
            if (result) {
                this.setState({loading: false, error: result} as unknown as CreateState<T>);
            } else {
                let state = {loading: false, error: undefined} as any;
                for (const key of Object.keys(this.props.edit)) {
                    state[key] = "";
                    state[key + "Error"] = undefined;
                }
                this.setState(state as CreateState<T>);
                this.props.onClose(true);
            }
        }
    }

    render() {
        return <Modal open={this.props.open} onClose={() => this.props.onClose(false)}>
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Paper sx={{padding: 6}}>
                    <Typography component="h1" variant="h5">{this.props.titel}</Typography>
                    <Box sx={{mt: 1}}>
                        {!!this.state.error ? <Alert severity="error">
                            <AlertTitle>Register Error</AlertTitle>
                            {this.state.error}
                        </Alert> : null}
                        {(Object.keys(this.props.edit) as (keyof typeof this.props.edit)[]).map(name => {
                            const edit = this.props.edit[name];
                            if (edit?.component) {
                                const Component = edit.component;
                                return <Component
                                    name={name}
                                    autoComplete={edit?.autoComplete ?? "off"}
                                    value={this.state[name]}
                                    label={edit?.label}
                                    onChange={this.change}
                                    required
                                    error={!!(this.state as any)[name + "Error"]}
                                />;
                            }
                            if (typeof this.state[name] == "boolean") {
                                return <FormControlLabel control={<Switch name={name} checked={this.state[name] as boolean} onChange={this.change}/>} label={edit?.label}/>;
                            }
                            return <TextField
                                margin="normal"
                                fullWidth
                                name={name}
                                label={edit?.label}
                                required
                                inputProps={{maxLength: 100}}
                                autoComplete={edit?.autoComplete ?? "off"}
                                value={this.state[name]}
                                onChange={this.change}
                                error={!!(this.state as any)[name + "Error"]}
                                helperText={(this.state as any)[name + "Error"]}
                            />;
                        })}
                        <LoadingButton
                            onClick={this.submit}
                            fullWidth
                            loadingPosition="start"
                            loading={this.state.loading}
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Register
                        </LoadingButton>
                    </Box>
                </Paper>
            </Box>
        </Modal>;
    }

}