import {ApiErrors as ApiErrorsType} from "../../../backend/src/types/api";

export const ApiErrors: ApiErrorsType = {
    parameter_missing: "Not all parameters fulfilled.",
    login_failed: "The credentials are wrong.",
    user_not_found: "The User where not found.",
    battery_not_found: "The Battery where not found.",
    token_error: "Could not create token.",
    token_invalide: "The supplied token is invalide.",
    no_token: "Access token not provided.",
    email_duplicate: "A user with this email already exists.",
    serial_duplicate: "A battery with this serial number already exists.",
    name_empty: "The name could not be empty.",
    name_to_long: "The name is longer than 20 chars.",
    no_permission: "You need to be admin.",
    parameter_mismatch: "Not all parameters are right type.",
    internal_error: "Internal error occurred.",
    no_battery_data: "Battery has no data.",
    invalide_battery_command: "Not a valide battery command",
    no_update_available: "No update for this version available",
    certificate_not_authorized: "Certificate not authorized"
};